// shared general styles for foundation space

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.info-message {
  font-size: 0.875rem;
  line-height: 1.275;
}

.invisible {
  visibility: hidden;
}

.foundation {
  display: flex;
  flex-direction: column;
  height: 100%;

  main {
    flex-grow: 1;
  }

  hr[data-content] {
    line-height: 1rem;
    position: relative;
    text-align: center;
    height: 1.5rem;
    outline: 0;
    border: 0;

    &:before {
      content: '';
      background: $grey-base;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 0.5px;
    }

    &:after {
      display: inline-block;
      content: attr(data-content);

      color: $grey-base;

      position: relative;

      padding: 0 0.5rem;
      line-height: 1.5rem;

      background-color: $background-grey;
    }
  }

  .auth-page {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      text-align: center;
      width: 50%;
      margin-bottom: 100px;

      form {
        width: 70%;
        align-items: center;
        margin: 0 auto;
      }

      @media (max-width: $tablet-size) {
        width: 70%;
        form {
          width: 100%;
        }
      }
    }
  }

  h1 {
    font-family: $foundation-primary-font-bold;
    text-align: left;
    margin-left: 0px;
    color: $black-foundation;
    font-weight: $bold;
  }

  h2 {
    font-size: 34px;
    line-height: 41px;
    font-family: $foundation-primary-font-bold;
    color: $black-dark;
    font-weight: 600;
    color: $black-foundation;
  }

  h3 {
    font-size: 25px;
    line-height: 29px;
    font-family: $foundation-primary-font;
    color: $black-dark;
    color: $black-foundation;
  }

  h4 {
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
    font-family: $foundation-primary-font;
    color: $black-dark;
  }

  h5 {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    font-family: $foundation-primary-font;
    color: $black-dark;
  }

  p,
  li {
    color: $black-foundation;
    font-family: $foundation-primary-font;
  }

  div {
    font-family: $foundation-primary-font;
  }

  label {
    color: $black-foundation;
    font-family: $foundation-primary-font;
    font-weight: unset;
  }

  // replicate logic in _buttons.scss in the event we ever need to put Foundation into a separate codebase
  .foundation-button-primary {
    background-color: $ozf-yellow;
    color: #1f1f1f;
    font-size: 14px;
    line-height: normal;
    font-family: $foundation-primary-font-bold;
    border-radius: 30px;
    align-items: flex-end;
    width: 200px;
    border: 0;
    text-transform: capitalize;
    @include rem(padding, 8px 20px);
    box-shadow: none;
    text-decoration: none;
    text-align: center;
    font-weight: normal;

    &.large {
      background-color: $ozf-yellow;
      color: #1f1f1f;
      font-size: 16px;
      line-height: normal;
      font-family: $foundation-primary-font-bold;
      border-radius: 30px;
      align-items: flex-end;
      width: 100%;
      border: 0;
      text-transform: capitalize;
      @include rem(padding, 16px 20px);
      box-shadow: none;
      text-decoration: none;
      text-align: center;
      font-weight: normal;
    }

    &.block {
      display: block;
    }

    &:hover:not(.is-disabled):not(.in-progress) {
      background-color: $ozf-yellow-darker;
      color: $black-dark;
      border: 0;
      cursor: pointer;
    }

    &:focus:not(.in-progress) {
      background-color: black;
      color: white;
    }

    &.is-disabled {
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    }

    &.in-progress {
      pointer-events: none;
      color: transparent;
      position: relative;
      text-shadow: none;

      span {
        color: transparent;
      }

      &:after {
        border: 2px solid rgba(255, 255, 255, 1);
        border-radius: 50%;
        border-right-color: transparent;
        border-top-color: transparent;
        content: '';
        display: block;
        height: 20px;
        animation: spin 0.5s linear infinite;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 20px;
        margin: auto;
      }
    }
  }

  .button-secondary {
    font-size: 14px;
    line-height: normal;
    font-family: $foundation-primary-font;
    font-weight: $bold;
    color: black;
  }

  .button-pop-up {
    background-color: $ozf-blue;
    color: $white-base;
    border: 0;
    letter-spacing: 1px;
    border-radius: 20px;
    @include rem(padding, 10px 20px 10px 20px);
    text-decoration: none;

    &:hover:not(.is-disabled):not(.in-progress) {
      background-color: $ozf-blue-darker;
      border: 0;
    }

    &:focus:not(.in-progress) {
      background-color: black;
      color: white;
    }
  }

  .clickable {
    &:hover {
      cursor: pointer;
    }
  }

  .checkbox {
    margin-top: 0px;
  }

  // RadioSelect

  @mixin radio-input {
    -webkit-appearance: none;
    appearance: none;
    transition: all 0.3s ease-in-out;
    background-color: $white-base;
    border: 1px solid $grey-base;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    @include rem(height, 20px);
    @include rem(width, 20px);
    outline: none;
    display: inline-block;
    @include rem(margin, 0px 8px 0px 0px);
    border-radius: 20em;
    vertical-align: top;

    &:focus {
      border-color: $black-base;
      outline: none;
    }

    &:checked {
      position: relative;

      &:after {
        background-color: $blue-base;
        color: $blue-base;
        content: '';
        display: block;
        height: 14.5px;
        transition: all 0.2s ease-in;
        text-align: center;
        width: 14.5px;
        -webkit-position: absolute;
        -webkit-z-index: 3;
        border-radius: 20em;
        @include vertical-horizontal-center;
      }
    }
  }

  $rounded-md: 0.375rem;

  .RadioGroup {
    width: 100%;
    @include rem(margin-bottom, 18px);

    &.inline-all {
      span,
      fieldset {
        @media (min-width: $tablet-size) {
          display: inline-block;
          width: auto;
        }
      }

      fieldset {
        @media (min-width: $tablet-size) {
          margin-left: 15px;
        }
      }

      label {
        @media (min-width: $tablet-size) {
          white-space: nowrap;
        }
      }

      span.error-message {
        display: block;
        width: auto;
      }
    }

    &.inline-inputs {
      fieldset {
        @media (min-width: $tablet-size) {
          display: inline-block;
          width: auto;
          white-space: nowrap;
        }
      }

      fieldset {
        @media (min-width: $tablet-size) {
          margin-right: 15px;
        }

        span {
          display: inline-block;
        }
      }

      span.error-message {
        display: block;
        width: auto;
      }
    }

    span label {
      margin-bottom: 10px;
    }

    fieldset {
      margin-bottom: 5px;

      @media (min-width: $mobile-size) {
        width: 100%;
        vertical-align: top;
      }

      &:last-child {
        margin-bottom: 0px;
      }

      label {
        font-family: $primary-sans-serif;
      }
    }

    label {
      cursor: pointer;
      line-height: normal;
      display: block;
    }

    span {
      color: $black-base;
      display: inline-block;
      @include rem(margin-top, 3px);
      width: calc(100% - 50px);

      p {
        white-space: normal;
      }
    }

    span.error-message {
      color: $red-base;
    }

    .input-wrapper {
      float: left;
    }

    input[type='radio'] {
      @include radio-input;
    }
  }
}

.foundation-cart {
  height: 100%;
}

.submission-confirmation {
  $vertical-spacing: 1.5rem;

  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .wrapper {
    width: fit-content;
  }

  h2 {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .content {
    margin: 0;
    margin-bottom: $vertical-spacing;
    padding: 1rem;

    background-color: $white-base;

    p {
      margin-bottom: 0.5rem;
      font-size: $s-medium;
    }
  }

  .call-to-action {
    margin: 0;
    margin-bottom: $vertical-spacing;
    padding: 1rem;
    border: 1px solid $ozf-green;

    background-color: $green-light;

    p {
      font-size: $s-base;
    }

    a {
      background-color: white;
    }
  }
}

.select-donation-page {
  margin: 3rem auto;
  background-color: $white-base;
  padding: 2rem;
  width: 50%;

  @media (min-width: $mobile-size) {
    h1 {
      font-size: 40px;
      margin-bottom: 1rem;
    }
  }

  @media (max-width: $tablet-size) {
    width: 100%;
    margin: 1rem auto;
  }
}

.account-nav {
  a {
    white-space: nowrap;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 2.5rem;
    width: 2.5rem;

    border-radius: 100%;
    background-color: $white-base;

    color: $black-dark;
    font-weight: bold;
    font-size: $s-big;
    text-decoration: none;

    img {
      height: 1.75rem !important;
      width: 1.75rem !important;
    }
  }
}

span {
  hr {
    display: inline-block;
    margin: 0 0.375rem;
    margin-bottom: -0.125rem;
    border: 0.5px solid $black-base;
    height: 1rem;
  }
}
