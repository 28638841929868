// Typography Variables
// ========================================

// Font Families

$primary-sans-serif: 'Roboto', sans-serif;
$secondary-sans-serif: 'Amatic SC', cursive;

$foundation-primary-font: 'FrutigerLTPro-Roman', sans-serif;
$foundation-primary-font-bold: 'FrutigerLTPro-Bold', sans-serif;

// Sizes
$font-size-base: 14px;

$s-giga: 60px;
$s-mega: 48px;

$s-huger: 38px;
$s-huge: 36px;
$s-larger: 30px;
$s-large: 27px;
$s-bigger: 22px;
$s-big: 18px;
$s-medium: 15.5px;
$s-base: 13px;
$s-small: 11px;
$s-tiny: 10px;

// Line Heights
$base-line-height: $font-size-base * 1.9;
$base-line-loose: $font-size-base * 2.2;
$base-line-small: $font-size-base;

// Font Weights
$light: 100;
$regular: 400;
$semibold: 600;
$bold: 700;
