.foundation {
  .modal-content-wrapper {
    h2 {
      font-family: $foundation-primary-font;
      font-size: $s-larger;
      font-weight: $bold;
      text-align: center;
    }

    .modal-content {
      p {
        font-family: $foundation-primary-font;
        font-size: $s-big;
        font-weight: $regular;
        text-align: center;
      }
    }

    .link-default {
      text-decoration: underline;
      font-size: 16px;
      color: $black-dark;
      margin-bottom: 0px;
    }
  }
}
