.foundation {
  .title {
    width: 100%;
    margin: auto;
    align-items: center;

    &__banner-group {
      position: relative;
      &__zoo-parent {
        width: 100%;
        font-size: 30px;
        line-height: 34px;
        font-weight: $bold;
        color: $white-base;
        text-align: center;
        z-index: 1;
        position: absolute;
        bottom: 5px;
        margin-bottom: 40px;
        padding: 0px 40px;

        @media (min-width: $tablet-smaller-size) {
          width: 80%;
          margin-left: 10%;
          margin-right: 10%;
          font-size: $s-huge;
          line-height: 40px;
          bottom: 15px;
          margin-bottom: 90px;
          padding: 0px 50px;
        }
      }

      &__banner-image {
        position: relative;
        margin-bottom: 0px;
        padding-bottom: 0px;

        img {
          width: 100%;
          object-fit: cover;
          background-position: center;
          min-height: 540px;

          @media (min-width: $tablet-smaller-size) {
            max-height: 600px;
          }
        }
      }
    }

    &__description-container {
      background-color: $ozf-green;
      margin-top: -5px;
      width: 100%;

      &--white {
        background-color: $white-base;
      }

      &--light {
        background-color: $grey-light;
      }

      &--light,
      &--white {
        & div,
        p {
          color: $black-foundation;
        }
      }

      &__description {
        text-align: center;
        color: $white-base;
        font-size: 24px;
        line-height: 34px !important;
        margin: auto;
        padding: 40px;
        max-width: 1200px;

        @media (min-width: $tablet-smaller-size) {
          font-size: 30px;
          line-height: 36px !important;
          padding: 90px 40px;
        }

        &__section {
          width: 100%;
          padding: 0 10px;
          color: $white-base;
          text-align: middle;

          @media (min-width: $tablet-smaller-size) {
            width: 50%;
            display: inline-block;
            vertical-align: middle;
            text-align: left;
          }

          &--title {
            font-weight: 600;
            font-size: 30px;
            margin-bottom: 20px;
            line-height: 30px;

            @media (min-width: $mobile-larger-size) {
              margin-bottom: 0;
            }
          }

          p {
            @media (min-width: $tablet-smaller-size) {
              font-size: 24px;
              line-height: 36px !important;
            }
          }

          & > ul {
            list-style: unset; // reset css overrides default unordered list behavior, which we want here
            & > li {
              color: $white-base;
              margin: 10px 5px;
              text-align: left;
            }
          }

          .foundation-button-primary {
            min-width: 264px;
            display: block;
            margin: 30px auto 0px;

            @media (min-width: $tablet-smaller-size) {
              margin: 30px 0px 0px;
            }
          }
        }
      }
    }
  }
  .all-animals {
    width: calc(100% - 80px);
    background-color: $white-base;
    margin: auto;
    padding: 40px;

    @media (min-width: $tablet-smaller-size) {
      width: calc(100% - 140px);
      background-color: $white-base;
      margin: 40px auto;
      padding: 50px;
      border-radius: 10px;
      max-width: 1300px;
    }
    .featured {
      margin: 0px auto 50px auto;
      border-radius: 10px;
      position: relative;
      background-size: cover;
      background-position: center;
      height: 300px;

      @media (min-width: $tablet-smaller-size) {
        height: auto;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 10px;

        @media (min-width: $mobile-larger-size) {
          aspect-ratio: 1200/500;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, transparent, black);
        border-radius: 10px;
        z-index: 0;
      }

      &__group {
        display: block;
        z-index: 1;
        position: absolute;
        bottom: 0px;
        padding: 50px 30px;

        &__banner {
          font-family: $foundation-primary-font;
          color: $white-base;
          font-size: $s-medium;
          margin-bottom: 15px;
        }

        &__product-name {
          font-family: $foundation-primary-font;
          font-weight: $bold;
          color: $white-base;
          font-size: $s-huge;
          width: 100%;
          color: $white-base;
          font-size: $s-huge;
          margin-bottom: 15px;
        }

        &__description {
          margin-bottom: 15px;
          &--text-block {
            color: $white-base;
            font-family: $foundation-primary-font;
          }
        }

        button.foundation-button-primary {
          background-color: $ozf-yellow;
          color: black;
        }
      }
    }
    .animal-container {
      a {
        text-decoration: none;
      }

      &__featured {
        width: 100%;
        grid-template-columns: 1fr;
      }

      &__tile {
        border-radius: 10px;
        margin-bottom: 25px;

        img {
          width: 100%;
          height: 100%;
          aspect-ratio: 1;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      &__product-name {
        width: 100%;
        font-family: $foundation-primary-font;
        font-size: $s-large;
        color: black;
      }

      &__product-description {
        &--status,
        &--location {
          font-size: $s-medium;
          color: $grey-foundation;

          span {
            text-transform: uppercase;
          }
        }

        &--status {
          margin: 10px 0px 0px 0px;
        }

        &--location {
          margin: 0px 0px 20px 0px;
        }
      }

      &__button.foundation-button-primary {
        min-width: 140px;
        width: auto;
      }
    }
  }
}

.foundation-button-primary {
  text-shadow: none;
  letter-spacing: 0px;
}
